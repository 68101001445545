import { isNewDate } from "@/helper/DateUtil";

export function nowIndex(appointments: Array<any>): number {
  if (!appointments.length) {
    return -1;
  }
  for (let i = 0; i < appointments.length; i++) {
    if (!isNewDate(appointments[i].date, appointments[i].time)) {
      return i;
    }
  }
  return appointments.length;
}
