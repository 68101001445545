
import { Options, Vue } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import AppointmentsHero from "@/components/Pages/Appointments/AppointmentsHero.vue";
import AppointmentsList from "@/components/Pages/Appointments/AppointmentsList.vue";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";

@Options({
  components: {
    ForwardHeader,
    AppointmentsList,
    AppointmentsHero,
    DefaultLayout,
  },
})
export default class Education extends Vue {}
