
import { Options, Watch } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import { isNewDate } from "@/helper/DateUtil";
import { nowIndex } from "@/helper/AppointementsHelper";
import { AppointmentTypeEnum } from "@/enums/AppointmentTypeEnum";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin";
import FscModal from "@/components/FscModal.vue";
import { isPlatform } from "@ionic/vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
//@ts-ignore
import VueSignaturePad from "vue-signature-pad";
import { dataURLtoFile } from "@/utils/File";

const AppointmentsModule = namespace("appointments");
const TheoryLessonDocumentModule = namespace("theory-lesson/uploadDocument");

@Options({
  components: { FscModal },
})
export default class AppointmentsList extends mixins(ModalMixin) {
  public name = "AppointmentsList";

  @AppointmentsModule.Action("findAll")
  public findAppointmentsAction: any;

  @AppointmentsModule.Action("update")
  public markAsSeenAppointmentsAction: any;

  @AppointmentsModule.Action("setNextAppointment")
  public setNextAppointmentAction: any;

  @AppointmentsModule.Getter("getDataList")
  public appointments: any;

  @AppointmentsModule.Getter("getIsLoading")
  public loading: any;

  @AppointmentsModule.Getter("getSuccess")
  public markSuccess: any;

  @TheoryLessonDocumentModule.Action("upload")
  public uploadDocument!: (options: ICrudOptions) => Promise<void>;

  @TheoryLessonDocumentModule.Getter("getSuccess")
  public uploadDocumentSuccess!: boolean;

  @TheoryLessonDocumentModule.Getter("getIsLoading")
  public uploadDocumentLoading!: boolean;

  private selectedTheoryLesson: any = null;

  public mounted(): void {
    this.load();
  }

  private load(): void {
    this.findAppointmentsAction({
      resource: "appointments/student/current",
      hideSuccessMessage: true,
    });
  }

  private markAsSeen(appointment: any): void {
    if (appointment.seen) {
      return;
    }
    this.markAsSeenAppointmentsAction({
      data: {
        appointmentType: appointment.appointmentType,
        appointmentId: appointment.appointmentId,
      },
      resource: "appointments",
      extraPath: "mark-as-seen",
      hideSuccessMessage: true,
    });
  }

  @Watch("markSuccess", { immediate: true, deep: true })
  private onMarkSuccess(success: any): void {
    if (true === success) {
      this.load();
    }
  }

  private getAppointmentClass(appointment: any): string {
    return isNewDate(appointment.date, appointment.time) ? "bg-color-trinidad-500 text-white" : "bg-white font-color-trinidad-500";
  }

  get nowIndex(): number {
    const index = nowIndex(this.appointments);
    if (index > 0) {
      this.setNextAppointmentAction(this.appointments[index - 1]);
    }
    return index;
  }

  public showSignature(appointment: any): boolean {
    return appointment?.appointmentType === AppointmentTypeEnum.THEORY_LESSON && appointment?.signed === false;
  }
  public showWebinarLink(appointment: any): boolean {
    return appointment?.appointmentType === AppointmentTypeEnum.THEORY_LESSON && appointment?.webinarLink;
  }

  public handleWebinarLink(appointment: any) {
    if (appointment?.webinarLink) {
      window.open(appointment.webinarLink, "_blank");
    }
  }

  public onSignature(appointment: any): void {
    const dateNow = Date.now();
    const currentDate = new Date(dateNow);

    const theoryDate = new Date(appointment.date);
    const [hrs, mins] = appointment.time.split(":");
    theoryDate.setHours(hrs, mins);

    if (currentDate > theoryDate) {
      this.showModal(null, "signaturePadModal");
    } else if (currentDate === theoryDate) {
      this.showModal(null, "signaturePadModal");
    } else {
      this.$toasted.error("Unterricht noch nicht freigeschaltet!");
    }
    this.selectedTheoryLesson = appointment;
  }

  public undo() {
    (this.$refs.signaturePad as VueSignaturePad).undoSignature();
    this.hideModal(null, "signaturePadModal");
  }

  public async save() {
    const { isEmpty, data } = (this.$refs.signaturePad as VueSignaturePad).saveSignature();

    if (!isEmpty && this.selectedTheoryLesson?.appointmentId) {
      const filename = `${this.selectedTheoryLesson.appointmentId}.png`;
      const formData = new FormData();
      formData.append("file", dataURLtoFile(data, filename));

      await this.uploadDocument({
        resource: `/theory-lesson-documents/upload-signature/student/current?theoryLessonId=${this.selectedTheoryLesson.appointmentId}`,
        descriptionField: "",
        data: formData,
      });

      if (this.uploadDocumentSuccess) {
        this.hideModal(null, "signaturePadModal");
        this.load();
      }
    }
  }

  public onBegin(): void {
    (this.$refs.signaturePad as VueSignaturePad).resizeCanvas();
  }

  public get isDesktop(): boolean {
    return isPlatform("desktop");
  }

  public get padH() {
    if (this.isDesktop) return "22vw";
    return "calc(100vh - 180px - var(--ion-safe-area-bottom))";
  }

  public get padW() {
    if (this.isDesktop) return "44vw";
    return "calc(95% - var(--ion-safe-area-right) - var(--ion-safe-area-left))";
  }
}
