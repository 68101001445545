import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "logo-header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_DriveBuzzLogo = _resolveComponent("DriveBuzzLogo")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, { class: "ion-no-border d-xl-none" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, {
        mode: "md",
        class: "mobile-header"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_button, {
            onClick: _ctx.onClickBack,
            slot: "start",
            class: "back-btn",
            mode: "md"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("general.button_previous")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          (!_ctx.hideLogo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_DriveBuzzLogo)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}