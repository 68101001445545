import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "@/config/Formats";

export function isNewDate(date: string, time = "00:00"): boolean {
  // @ts-ignore
  const now = moment();
  // @ts-ignore
  const formattedDate = moment(date + " " + time);
  return now < formattedDate;
}

// new questions come every april or october
export function newVersionDate(): string {
  const currentMonth = new Date().getMonth();
  const newVersionMonth = currentMonth > 3 && currentMonth < 9 ? 3 : 9;
  return moment({ month: newVersionMonth }).format(DEFAULT_DATE_FORMAT);
}

export function formattingDate(date: any) {
  if (!date) {
    return "";
  } else {
    return moment(date).format("DD.MM.YYYY");
  }
}
